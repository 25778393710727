html {
  scroll-behavior: smooth;
}
.ps-timeline-sec {
  position: relative;
}
.ps-timeline-sec .container {
  position: relative;
}
@media screen and (max-width: 1200px) {
  .ps-timeline-sec .container ol:before {
    background: #348e80;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: 83px !important;
    left: 21px !important;
  }
  .ps-timeline-sec .container ol:after {
    background: #348e80;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: inherit !important;
    left: 21px;
  }
  .ps-timeline-sec .container ol.ps-timeline {
    margin: 50px 0 450px !important;
    border-left: 2px solid #348e80;
    padding-left: 0 !important;
    padding-top: 120px !important;
    border-top: 0 !important;
    margin-left: 25px !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li {
    height: 320px;
    float: none !important;
    width: inherit !important;
  }
  .ps-timeline-sec
    .container
    ol.ps-timeline
    li:nth-child(2)
    .img-handler-bot
    img {
    width: 70px;
  }
  .ps-timeline-sec .container ol.ps-timeline li:last-child {
    margin: 0;
    bottom: 0 !important;
    height: 120px;
  }
  .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot {
    bottom: 40px !important;
    width: 40% !important;
    margin-left: 25px !important;
    margin-top: 0 !important;
  }
  .ps-timeline-sec
    .container
    ol.ps-timeline
    li:last-child
    .img-handler-bot
    img {
    width: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li:last-child .ps-top {
    margin-bottom: 0 !important;
    top: 20px;
    width: 50% !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li span {
    left: 0 !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
    content: none !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
    content: none !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
    content: none !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
    content: none !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
    position: absolute !important;
    bottom: 150px !important;
    width: 30% !important;
    float: left !important;
    margin-left: 35px !important;
    margin-bottom: 0 !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
    margin: 0 auto !important;
    width: 80% !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
    position: absolute !important;
    bottom: 115px !important;
    width: 30% !important;
    float: left !important;
    margin-left: 35px !important;
    margin-bottom: 0 !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li p {
    text-align: left !important;
    width: 100% !important;
    margin: 0 auto !important;
    margin-top: 0px !important;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-top {
    width: calc(100% - 50px) !important;
    float: right !important;
    left: 40px;
    top: 0px;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
    width: calc(100% - 50px) !important;
    float: right !important;
    left: 40px;
    top: -40px;
  }
}
.hero-image {
  position: relative;
  top: 0;
}
@media (min-width: 768px) {
  .hero-image {
    position: relative;
    top: -55px;
  }
}
.ps-timeline-sec .container ol:before {
  background: #348e80;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  left: 0px;
  top: 43.8%;
}
.ps-timeline-sec .container ol:after {
  background: #348e80;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  right: 0px;
  top: 43.8%;
}
.ps-timeline-sec .container h3 {
  text-align: center;
}
.ps-timeline-sec .container ol.ps-timeline {
  margin: 350px 0 490px;
  padding: 0;
  border-top: 2px solid #348e80;
  list-style: none;
}
.ps-timeline-sec .container .joke {
  position: relative;
  margin: auto;
  top: -15px;
  left: 40px;
  width: 300px;
  max-width: 300px;
}
@media screen and (max-width: 767px) {
  .ps-timeline-sec .container .joke {
    position: relative;
    margin: auto;
    top: 0;
    left: -100px;
    width: 300px;
    max-width: 300px;
  }
}
.ps-timeline-sec .container ol.ps-timeline li {
  float: left;
  width: 33%;
  padding-top: 30px;
  position: relative;
}
.ps-timeline-sec .container ol.ps-timeline li span {
  width: 50px;
  height: 50px;
  margin-left: -25px;
  background: #fff;
  border: 4px solid #348e80;
  border-radius: 50%;
  box-shadow: 0 0 0 0px #fff;
  text-align: center;
  line-height: 42px;
  color: #ff7d14;
  font-size: 28px;
  font-style: normal;
  position: absolute;
  top: -26px;
  left: 50%;
}
sup {
  font-size: 50%;
}
.ps-timeline-sec .container ol.ps-timeline li p {
  color: #ff7d14;
  font-size: 20px;
  margin-bottom: 10px !important;
}
.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
  content: "";
  color: #348e80;
  width: 2px;
  height: 50px;
  background: #348e80;
  position: absolute;
  bottom: -50px;
  left: 50%;
}
.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
  content: "";
  color: #348e80;
  width: 8px;
  height: 8px;
  background: #348e80;
  position: absolute;
  top: 90px;
  left: 44%;
  border-radius: 100%;
}
.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
  content: "";
  color: #348e80;
  width: 2px;
  height: 50px;
  background: #348e80;
  position: absolute;
  top: -50px;
  left: 50%;
}
.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
  content: "";
  color: #348e80;
  width: 8px;
  height: 8px;
  background: #348e80;
  position: absolute;
  bottom: 90px;
  left: 44%;
  border-radius: 100%;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
  position: absolute;
  bottom: 0;
  margin-bottom: 130px;
  width: 100%;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
  display: table;
  margin: 0 auto;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
  position: absolute;
  margin-top: 60px;
  width: 100%;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-bot img {
  display: table;
  margin: 0 auto;
}
.ps-timeline-sec .container ol.ps-timeline li p {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}
.ps-timeline-sec .container ol.ps-timeline li .ps-top {
  position: absolute;
  bottom: 0;
  margin-bottom: 130px;
}
.ps-timeline-sec .container ol.ps-timeline li .ps-bot {
  position: absolute;
  margin-top: 60px;
}
.ps-timeline-sec .container ol.ps-timeline li .ps-top small,
.ps-timeline-sec .container ol.ps-timeline li .ps-bot small {
  font-size: 18px;
}
.ps-timeline-sec .container ol.ps-timeline li .ps-top small.dot,
.ps-timeline-sec .container ol.ps-timeline li .ps-bot small.dot {
  font-size: 25px;
  line-height: 25px;
}